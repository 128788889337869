import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Avatar, IconButton, ThemeProvider, Tooltip, Typography} from "@mui/material";
import {DarkTheme, LightTheme} from "./components/style/Theme";
import {TonalPalette} from "./components/style/tonalPalette";
import ThemColorController from "./components/style/ThemColorController";
import {Masonry} from "@mui/lab";
import {AccountCircle, DarkMode, GitHub, LightMode, Person} from "@mui/icons-material";
import {withAlpha, withLightness} from "./components/style/colorUtils";

import ProfilePicture from "./resources/profile_picture/WOLFF-leo_cropped-square.jpg";

const defaultThemeColor = "#7cb9d0";

const heights = [150, 30, 90, 70, 110, 150, 130, 80, 50, 90, 100, 150, 30, 50, 80];

const AVATAR_DIM = 150;


function App() {
	const [themeColor, setThemeColor] = useState(defaultThemeColor);
	const [darkMode, setDarkMode] = useState(false);

	const theme = useMemo(() => {
		const tonalPalette = TonalPalette.fromColor(themeColor);
		return darkMode ? DarkTheme(tonalPalette) : LightTheme(tonalPalette);
	}, [themeColor, darkMode]);

	useEffect(() => {
		document.body.style.backgroundColor = theme.palette.background.default;
	}, [theme.palette.background.default]);

	const toSurface = useCallback(function (hex: string) {
		return withAlpha(darkMode ? hex : withLightness(hex, 0.05), 0.05);
	}, [darkMode]);

	return (
		<ThemeProvider theme={theme}>
			<div className="App" style={{
				marginTop: 30,
				display: "flex", flexDirection: "column",
				justifyContent: "center", alignItems: "center",
				width: "60%",
			}}>
				<div style={{
					width: "100%", borderRadius: "8px",
					display: "flex", flexDirection: "row",
					justifyContent: "start", alignItems: "center",
					gap: 20,
				}}>
					<Avatar sx={{width: AVATAR_DIM, height: AVATAR_DIM}} src={ProfilePicture}/>
					<Typography
						color={theme.palette.getContrastText(theme.palette.background.default)}
						variant={"h3"}
						fontWeight={"lighter"}
					>
						Léo Wolff
					</Typography>
				</div>
				<div style={{
					width: "100%",
					padding: 5, borderRadius: "8px",
					display: "flex", flexDirection: "row",
					justifyContent: "start", alignItems: "center",
					gap: 5,
				}}>
					<Tooltip title={"To GitHub profile..."}>
						<IconButton
							style={{color: theme.palette.primary.main}}
							href={"https://github.com/WollfieGitHub"}
							target={"_blank"}
						>
							<GitHub fontSize={"large"}/>
						</IconButton>
					</Tooltip>
				</div>
				<div style={{
					width: "100%",
					display: "flex", flexDirection: "row",
					justifyContent: "center", alignItems: "center",
					backgroundColor: theme.palette.secondaryContainer.main,
					color: theme.palette.secondaryContainer.contrastText,
					padding: 10, borderRadius: "8px", gap: 5,
					boxSizing: "border-box",
				}}>
					<ThemColorController color={"secondary"} defaultColor={defaultThemeColor} onColorChanged={setThemeColor}/>
					<IconButton
						style={{color: theme.palette.secondary.main}}
						size={"small"}
						onClick={() => setDarkMode(!darkMode)}
					>
						{darkMode
							? <LightMode/>
							: <DarkMode/>
						}
					</IconButton>
				</div>
				<div style={{width: "100%"}}>
					<div style={{width: "100%"}}>
						<Masonry columns={3} spacing={0} sx={{
							width: `calc(100% - 32px)`, boxSizing: "border-box",
							marginLeft: "-8px",
						}}>
							{heights.map((height, index) => (
								<div key={index} style={{
									margin: "8px",
									backgroundImage: "",
									borderRadius: "8px",
									backgroundColor: toSurface(theme.palette.primaryContainer.main),
									color: theme.palette.primaryContainer.contrastText,
									height: Math.max(50, height),
								}}>
									{index + 1}
								</div>
							))}
						</Masonry>
					</div>
				</div>
			</div>
		</ThemeProvider>
	);
}

export default App;
