import {useEffect, useState} from "react";
import {hexToRgb, hslToRgb, rgbDictToHex, rgbDictToHsl} from "./colorUtils";
import {Slider, SliderProps} from "@mui/material";

interface ThemColorControllerProps {
	defaultColor: string;
	onColorChanged: (color: string) => void;
	color: SliderProps["color"],
}


export default function ThemColorController(
	{ color, defaultColor, onColorChanged}: ThemColorControllerProps,
) {
	const [colorHue, setColorHue] = useState(rgbDictToHsl(hexToRgb(defaultColor)).h);

	useEffect(() => {
		const {s, l} = rgbDictToHsl(hexToRgb(defaultColor));

		onColorChanged(rgbDictToHex(hslToRgb(colorHue, s, l)));

	}, [colorHue, defaultColor, onColorChanged]);

	return <Slider
		color={color}
		min={0} max={360} step={10} defaultValue={colorHue*360} size={"small"}
		onChange={(e, newValue) => {
			setColorHue((
				Array.isArray(newValue) ? newValue[0] : newValue
			) / 360);
		}}
		style={{}}
	/>;
}